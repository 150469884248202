import React, { useState, useEffect, useContext } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useMutation } from "@apollo/client"
import { Redirect, useLocation } from "@reach/router"
import queryString from "query-string"
import { getAppSyncClientContext } from "../ApolloWrapper"
import { createFormSubmission } from "../graphql/mutations"
import gql from "graphql-tag"

import { useTranslation } from "../i18n"
import { Seo } from "../seo"

import * as actions from "../store/actions"

import GridLayout from "../Layout/GridLayout"
import Img from "gatsby-image/withIEPolyfill"
import { graphql, useStaticQuery } from "gatsby"
import { Loading } from "../Layout/Components"
import { string } from "prop-types"
import { MarketingPolicy, NewsletterPolicy, ProfilingPolicy } from "../User/Profile/Policies"

import ReCAPTCHA from "react-google-recaptcha"

const Preferences = () => {
  const { t, i18n } = useTranslation(["translation", "errors", "preferences"])
  const location = useLocation()
  const dispatch = useDispatch()
  const [msg, setMsg] = useState("")
  const [manageData, setManageData] = useState({} as any)
  const [manageCode, setManageCode] = useState("")

  // Image
  const data = useStaticQuery(graphql`
    query {
      people: file(relativePath: { eq: "ecoverso.people.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  useEffect(() => {
    const queryParams: any = queryString.parse(location.search)

    // Somebody landed on this page to verify an email
    if (queryParams.verify && queryParams.verify.match(/[0123456789abcdef]{32}-[0-9]{6}/g)) {
      setMsg("WAIT")
      setManageCode(queryParams.verify)
      retrieveOrUpdatePreferences({}, queryParams.verify, "VERIFIED", "verify-email")
      // submitAndManageResult({ verify: queryParams.verify }, "verify-email", (res) => {
      //   window.location.replace(`/preferences/?msg=` + res)
      // })
    }

    // Somebody landed on this page because he wants to manage preferences
    if (queryParams.email && queryParams.email.match(/.+@.+\..+/g)) {
      setMsg("CAPTCHA")
    }

    // Somebody landed on this page with the link that enables to manage preferences
    if (queryParams.manage && queryParams.manage.match(/[0123456789abcdef]{32}-[0-9]{6}/g)) {
      setManageCode(queryParams.manage)
      retrieveOrUpdatePreferences({}, queryParams.manage, "MANAGE")
    }

    // Final page used to show a message to user
    if (queryParams.msg) {
      setMsg(queryParams.msg)
    }

    console.log(queryParams)
  }, [location])

  const handleCaptcha = (v) => {
    const queryParams: any = queryString.parse(location.search)
    setMsg("WAIT")
    submitAndManageResult({ email: queryParams.email }, "access-preferences", (res) => {
      window.location.replace(`/preferences/?msg=` + res)
    })
  }

  // --- NEW formSubmission ---------------------------------------------------------------------------------------------
  const getAppsyncClient = useContext(getAppSyncClientContext)
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn)
  console.log("Login: ", isLoggedIn)
  const [submitForm, { error: formSubmissionError }] = useMutation(gql(createFormSubmission), {
    client: getAppsyncClient(isLoggedIn),
  })

  if (formSubmissionError) {
    dispatch(actions.notificationAdd({ message: t("Form Submission Error"), variant: "DangerIcon" }))
    console.log("Form Submission Error", formSubmissionError)
    return null
  }

  const submitAndManageResult = (jsonData: any, formName: string, callback: Function) => {
    submitForm({
      variables: {
        createForm_submissionInput: {
          jsonData: JSON.stringify(jsonData),
          formName: formName,
        },
      },
    }).then((data) => {
      console.log(data)
      if (data?.data?.createForm_submission?.result) {
        callback(data?.data?.createForm_submission?.result)
      } else {
        dispatch(actions.notificationAdd({ message: t("Form Submission Error"), variant: "DangerIcon" }))
      }
    })
  }
  // --------------------------------------------------------------------------------------------------------------------

  // Somebody landed on this page with the link that enables to manage preferences
  // Or somebody is already using the page to make changes
  const retrieveOrUpdatePreferences = (data, oneShotManageCode?, oneShotViewMode?, oneShotFormName?) => {
    const viewModeToSet = oneShotViewMode || msg
    setMsg("WAIT")
    submitAndManageResult({ ...data, verify: oneShotManageCode || manageCode }, oneShotFormName || "manage-preferences", (res) => {
      try {
        const resultData = JSON.parse(res)
        setManageData(resultData)
        if (resultData.acptNewsletter && resultData.acptMarketing && resultData.acptProfiling && viewModeToSet == "VERIFIED") {
          window.location.replace(`/preferences/?msg=OK`)
        } else {
          setMsg(viewModeToSet)
        }
      } catch (e) {
        // no JSON returned, probably link is expired
        window.location.replace(`/preferences/?msg=` + res)
      }
      console.log(res)
    })
  }

  let message = <div>Siamo spiacenti, si è verificato un errore.</div>
  switch (msg) {
    case "CAPTCHA":
      message = (
        <div style={{ textAlign: "left" }}>
          Per proseguire verifica di essere umano :-)
          <br />
          <br />
          <ReCAPTCHA sitekey="6Lcy36YcAAAAAIoLW-q_8XKQdY321DZwWtEV3leA" onChange={handleCaptcha} />
        </div>
      )
      break
    case "WAIT":
      message = (
        <div style={{ textAlign: "center" }}>
          Attendere...
          <br />
          <img src="/img/ecoverso.spinner.gif" style={{ height: "3rem", marginTop: "1rem" }} />
        </div>
      )
      break
    case "KO-NotFound":
      message = (
        <div>
          <p>Siamo spiacenti, ma non abbiamo trovato alcuna corrispondenza con il link cliccato.</p>
          <p>È possibile che si tratti di un vecchio link, prova a effettuare una nuova richiesta.</p>
        </div>
      )
      break
    case "KO-NewVerificationEmail":
      message = (
        <div>
          <p>Siamo spiacenti, il link non è più valido.</p>
          <p>Ti abbiamo inviato una nuova email di verifica.</p>
        </div>
      )
      break
    case "OK-PreferencesLinkEmail":
      message = (
        <div>
          <p>Richiesta ricevuta ;-)</p>
          <p>Abbiamo provveduto a inviarti una mail con un link (valido 5 minuti) per gestire le tue preferenze email.</p>
        </div>
      )
      break
    case "OK":
      message = (
        <div>
          <p>Grazie mille!</p>
          <p>La tua casella di posta è stata verificata.</p>
        </div>
      )
      break
  }

  return (
    <GridLayout>
      <Seo title={t("Conferma")} noindex />
      <div className="row row--center row--no-grid pb-none preferences--holder">
        {msg != "MANAGE" && msg != "VERIFIED" ? (
          <div className="login__modal preferences--box">
            <Img fluid={data.people.childImageSharp.fluid} objectFit="cover" objectPosition="50% 50%" className="login__modal-image" />
            <div style={{ position: "relative" }}>
              <div className="preferences--message">{message}</div>
            </div>
          </div>
        ) : null}
        {manageData.email != undefined && (msg == "MANAGE" || msg == "VERIFIED") ? (
          <React.Fragment>
            {msg == "MANAGE" ? <h2>Gestisci le preferenze per {manageData.email}</h2> : <h2>Grazie, la casella {manageData.email} è stata verificata!</h2>}
            {msg == "MANAGE" || !manageData.acptNewsletter ? (
              <NewsletterPolicy
                accepted={manageData.acptNewsletter}
                date={manageData.updtNewsletter}
                onSubmit={(v) => retrieveOrUpdatePreferences({ acptNewsletter: v ? 1 : 0 })}
              />
            ) : null}
            {msg == "MANAGE" || !manageData.acptMarketing ? (
              <MarketingPolicy
                accepted={manageData.acptMarketing}
                date={manageData.updtMarketing}
                onSubmit={(v) => retrieveOrUpdatePreferences({ acptMarketing: v ? 1 : 0 })}
              />
            ) : null}
            {msg == "MANAGE" || !manageData.acptProfiling ? (
              <ProfilingPolicy
                accepted={manageData.acptProfiling}
                date={manageData.updtProfiling}
                onSubmit={(v) => retrieveOrUpdatePreferences({ acptProfiling: v ? 1 : 0 })}
              />
            ) : null}
          </React.Fragment>
        ) : null}
      </div>
    </GridLayout>
  )
}

export default Preferences
